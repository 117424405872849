<template>
    <div class="">
        <!-- <modal-tool-transfer-confirm :thisModal="modalNewToolTransferConfirm" modalId="modalNewToolTransferConfirm" title="Transfer" :toolTransfer="selectedToolTransfer" @confirmed="confirmedToolTransfer" /> -->
        <modal-add-edit-tool-service :thisModal="modalAddEditToolService" modalId="modalAddEditToolService" title="Servis" :editToolService="selectedToolService" @confirmed="confirmedToolService" />
        <h2>Servisi alata <span @click="refreshData()"><font-awesome-icon icon="sync" /></span></h2>
        
        <div>
            <ejs-grid
                ref="allToolServices"
                :dataSource="allToolServices"
                class="all-tool-damages-grid"
                :allowFiltering='true'
                :allowSorting='true'
                :showColumnMenu='true'
                :filterSettings='filterSettings'
                :allowPaging='paginatorVissible' 
                :pageSettings='pageSettings'

                :allowSelection='false'
                :enableHover='true'
                :enableHeaderFocus='true'

                :recordClick="toolDBClick"
                :allowResizing='true'
                :allowTextWrap='true'

                >
                <e-columns>
                    <e-column field='id' headerText='ID' width='120' textAlign='Right' :isPrimaryKey='true' :visible='false' ></e-column>

                    <e-column field='tool_obj.name' headerText='Naziv' width='120' textAlign='Left' :visible='true' :template="toolsNameTemplate"></e-column>
                    <e-column field='tool_obj.identification' headerText='Br' width='60' textAlign='Left' :visible='true'></e-column>
                    

                    <e-column field='service_start' :valueAccessor='dateAccessor' headerText='Početak' width='100' textAlign='Right' :visible='true'></e-column>
                    <e-column field='service_end' :valueAccessor='dateAccessor' headerText='Kraj' width='100' format="yMd" textAlign='Right' :visible='true'></e-column>


                    <e-column field='updated_at' :valueAccessor='dateTimeAccessor' headerText='Poslednja izmena' width='200' format="yMd HH:mm" textAlign='Right' :visible='true'></e-column>
                    <e-column field='modified_by.username' :valueAccessor='fullnameAccessor' headerText='Poslednji izmenio' width='130' :visible='false'></e-column>
                    <e-column field='created_at' :valueAccessor='dateTimeAccessor' headerText='Kreirano' width='200' format="yMd HH:mm" textAlign='Right' :visible='false'></e-column>
                    <e-column field='created_by.username' :valueAccessor='fullnameAccessor' headerText='Kreirao' width='130' :visible='false'></e-column>


                </e-columns>
            </ejs-grid>    
        </div>
    </div>
    
</template>


<script>

    import {mapState} from 'vuex'
    // import RequestStatus from '@/components/RequestStatus.vue';
    // import { Modal } from 'bootstrap';
    import UtilService from '@/service/UtilService.js';

    import ToolsService from '@/service/ToolsService.js';
    import { Sort, Page, ColumnMenu, Filter, Group, Toolbar, Edit, Resize } from "@syncfusion/ej2-vue-grids";
    import { Modal } from 'bootstrap'
    // import ToolTypesButtons from '@/components/tools/ToolTypesButtons.vue';
    // import ToolsWarehouseButtons from '@/components/tools/ToolsWarehouseButtons.vue';
    import ToolsCMD from '@/components/grid-templates/ToolsCMD.vue';
    import ModalAddEditToolService from '@/components/modals/tools/ModalAddEditToolService.vue';


    export default {
        components: {
            ModalAddEditToolService
        },
  
       data() {
         
            return {
                modalAddEditToolService: null,


                allTools: [],
                allToolServices: [],

                selectedToolService: undefined,

                toolType: undefined,
                warehouse: undefined,
                intervalRefresh: undefined,
                refreshCount: 0,

                filterSettings: { type: "Excel" },
                pageSettings: { pageCount: 20},
                editSettings: { allowEditing: true, allowAdding: true, allowDeleting: true },


                toolsNameTemplate: function() {
                    return {
                        template: {
                            extends: ToolsCMD,
                            propsData: {
                                column: "TOOL_NAME_IMAGE"
                            },   
                        }
                    };
                },


                // toolTransfersCMD: function() {
                //     return {
                //         template: {
                //             extends: ToolTransfersCMD,
                //             propsData: {
                //                 column: "CMD"
                //             },
                            
                //         }

                //     };
                // },


                
            }
        },

        provide: {
            grid: [Sort, Page, ColumnMenu, Filter, Group, Toolbar, Edit, Resize ]
        },

        computed: {
            ...mapState([
                'user',
            ]),

            isAdmin() {
                if (this.user.role === 'ADMIN') return true;
                return false;
            },

            isManager() {
                if (this.user.role === "MANAGER" || this.user.role === "ADMIN" || this.userAssignment?.pwo?.pwo_role == 1)
                    return true;
                return false;
            },

            paginatorVissible() {
                if (this.allToolServices?.length > 20) return true;
                return false;
            }
        },


        

        methods: {
            
            formatDate: UtilService.formatDate,
            formatDateTime: UtilService.formatDateTime,
        
            fullnameAccessor(field, data) {
                let firstField = field.split('.')[0];
                let userData = data?.[firstField];
                console.log(userData);
                if (!userData?.first_name) return;
                return userData.first_name + " " + userData.last_name; 
            },

            dateTimeAccessor(field, data) {
                return data[field] ? this.formatDateTime(data[field]) : '/';
            },


            dateAccessor(field, data) {
                return data[field] ? this.formatDate(data[field]) : '/';
            },

            loadAllReportedTools() {
                let query = {
                }

                ToolsService.getAllToolsServices(query).then(async (result)=>{
                    this.allToolServices = result.data
                });
            },


            loadData() {
                this.loadAllReportedTools();
            },

            toolDBClick(event) {
                console.log(event);
                this.showModalAddEditToolService(event.rowData);
            },

            showModalAddEditToolService(selected) {
                this.selectedToolService = selected;
                this.modalAddEditToolService.show();
            },

            deleteSelectedTool() {
                return;
            },

            confirmedToolService() {
                this.loadData();
            },

            refreshData() {
                this.loadData();
            },

        },


        created() {
        },

        mounted() {
            this.loadData();
            this.modalAddEditToolService = new Modal(document.getElementById('modalAddEditToolService'));
        },


    }
</script>

<style>

    .all-tool-transfers-grid table tr {
        cursor: pointer;
    }

</style>
