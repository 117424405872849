<template>
    <div>

        <template v-if="column === 'IMAGE'">
            <div class="image-cell">
                <router-link :to="{ name: 'tool-profile', params: { toolId: data.id }}">
                    <img v-if="data.image_url" :src="data.image_url" alt="Slika" />

                    <font-awesome-icon v-else size="2x" icon="image"></font-awesome-icon>
                </router-link>
            </div>
        </template>

        <template v-if="column === 'NAME'">
            <router-link :to="{ name: 'tool-profile', params: { toolId: data.id }}">
                <span>{{data.name}}</span>
            </router-link>
        </template>

        <template v-if="column === 'TOOL_NAME_IMAGE'">

            <div class="textimage-cell">
                <router-link :to="{ name: 'tool-profile', params: { toolId: data.tool_obj.id }}">
                    <img v-if="data.tool_obj.image_url" :src="data.tool_obj.image_url" alt="Slika" />

                    <font-awesome-icon v-else size="2x" icon="image"></font-awesome-icon>

                    <span>{{data.tool_obj.name}} <em>[2]</em></span>

                </router-link>
            </div>
        </template>

        <template v-if="column === 'CMD'">
                
                <template v-if="data.transfer_pending">
                    <button type="button" class="btn btn-light" @click="$parent.$parent.showToolTransferConfirmModal(data)"><font-awesome-icon size="2x" icon="check" /><h5>Obradi transfer</h5></button>
                </template>
                <template v-else>
                    <button v-if="isAdmin" type="button" class="btn" @click="$parent.$parent.showEditToolModal(data)"><font-awesome-icon icon="pen"></font-awesome-icon></button>
                    <button type="button" class="btn btn-light" @click="$parent.$parent.showToolTransferModal(data)"><font-awesome-icon size="2x" icon="exchange-alt"></font-awesome-icon></button>
                </template>

        </template>


        <template v-if="column === 'EMP_CMD'">
                <button type="button" class="btn btn-light" @click="$parent.$parent.showToolTransferModal(data)"><font-awesome-icon size="2x" icon="exchange-alt"></font-awesome-icon></button>
        </template>
    </div>
</template>

<script>

    import {mapState} from 'vuex'

    export default {
        props: ['column', 'quickAction'],

        data: function() {
            return {
                data: {},
            }
        },

        computed: {
            ...mapState([
                'user',
            ]),

            isAdmin() {
                if (this.user.role == "ADMIN") return true;
                return false;
            }
        },

        methods: {

        },

        mounted() {
        }

    }
</script>

<style scoped>
    .image-cell img {
        max-width: 100%;
        max-height: 200px;
    }

    .textimage-cell img {
        max-width: 50px;
        float: left;
        margin-right: 10px;
    }
</style>