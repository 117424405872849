<template>
    <div class="modal fade" :id="modalId" tabindex="-1" :aria-labelledby="modalId + 'Label'" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel"> {{ title }} </h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <div class="modal-body">
                    <div>
                        <form @submit.prevent="" id="tool-service-form">
                            <div class="d-grid gap-2">

                                <div class="form-floating">
                                    <input v-model="toolService.name" class="form-control" type="text" placeholder="" aria-label="Popravka" required>
                                    <label for="floatingInput">Popravka:</label>
                                    <div class="invalid-feedback">
                                        Naziv je obavezno polje.
                                    </div>
                                </div>

                                <div class="form-floating">
                                    <input v-model="toolService.service_name" class="form-control" type="text" placeholder="" aria-label="Naziv firme">
                                    <label for="floatingInput">Naziv firme:</label>
                                    <div class="invalid-feedback">
                                        Naziv firme je obavezno polje.
                                    </div>
                                </div>

                               

                                <div class="form-floating">
                                    <input v-model="toolService.price" step=".01" class="form-control" type="number"  placeholder="5000 RSD" aria-label="Cena" required>
                                    <label for="floatingInput">Cena popravke (RSD):</label>
                                    <div class="invalid-feedback">
                                        Cena popravke je obavezno polje.
                                    </div>
                                </div>

                                <div class="mb-3 form-floating">
                                    <textarea rows="3" class="form-control text-area-floating" v-model="toolService.description" placeholder="Opis" />
                                    <label for="woCode">Opis</label>
                                </div>

                                <div class="form-floating">
                                    <input v-model="toolService.service_start" class="form-control" type="date" placeholder="Početni datum:" aria-label="Pocetak servisa" required>
                                    <label for="floatingTextarea">Početak servisa</label>
                                </div>

                                <div class="form-floating">
                                    <input v-model="toolService.service_end" class="form-control" type="date" placeholder="Krajnji datum:" aria-label="Kraj servisa">
                                    <label for="floatingTextarea">Kraj servisa</label>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"> Zatvori </button>
                    <button :disabled="uploading" @click="submitTool()" type="button" class="btn btn-primary">
                        Potvrdi
                        <div v-if="uploading" class="spinner-border" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import ToolsService from '@/service/ToolsService.js';

    export default {

        props: {
            modalId: String,
            title: String,
            editToolService: Object,
            thisModal: Object,
            toolId: Number,
        },

        components: {
        },

        data() {
            return {
                toolService: {
                    id: undefined,
                    name: "",
                    description: "",
                    price: undefined,
                    service_start: null,
                    service_end: null,
                },

                uploading: false,
                file: [],

                reader: false,

            }
        },

        computed: {
            ...mapState([
            ]),


        },


        methods: {

            validate() {
                let form = document.getElementById("tool-service-form");
                form.classList.add('was-validated');

                if (!form.checkValidity())
                    return false;

                return true;
            },

            submitTool() {
                if (!this.validate()) return

                if (this.editToolService === undefined) this.addToolService();
                else this.putToolService();


            },

            putToolService() {
                ToolsService.putToolService(this.toolService).then(result => {
                    console.log(result);
                    this.thisModal.hide();
                    this.$emit('confirmed');
                })
            },

            addToolService() {
                ToolsService.postToolService(this.toolService).then(result => {
                    console.log(result);
                    this.thisModal.hide();
                    this.$emit('confirmed');

                })
            },

            initData() {
                console.log("init");
                if (this.editToolService !== undefined) {
                    this.toolService = {
                        id: this.editToolService.id,
                        name: this.editToolService.name,
                        description: this.editToolService.description,
                        price: this.editToolService.price,
                        service_start: this.editToolService.service_start,
                        service_end: this.editToolService.service_end,
                        tool: this.editToolService.tool,
                    }
                } else {
                    this.toolService = {
                        id: undefined,
                        name: "",
                        description: "",
                        price: undefined,
                        service_start: null,
                        service_end: null,
                        tool: this.toolId,
                    }
                }
            },

            setUpListener() {
                let myModalEl = document.getElementById(this.modalId)
                myModalEl.addEventListener('shown.bs.modal', () => {
                    this.initData();
                })
            },
            
        },

        mounted() {
            this.setUpListener();
        },

        created() {
            this.initData();
        }

    }
</script>

<style scoped>
.v-select.form-control{
    height: 4.5em;
}
</style>